import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"
import noImage from "../../images/no-image.png"
import "./Sponsorships.scss"

const Sponsorships = () => {
    const sponsorshipsQuery = useStaticQuery(graphql`
    query {
        allMarkdownRemark(sort: {fields: [frontmatter___index], order: ASC}) {
            nodes {
              excerpt
              fields {
                slug
              }
              frontmatter {
                index
                title
                description
                thumbnail
                pagelink
              }
            }
          }
    }`);

    const sponsorships = sponsorshipsQuery.allMarkdownRemark.nodes.filter(entity => entity.fields.slug?.includes("/sponsorship-") ? entity : null);

    return (
        <section id="sponsorships-page" className="page-section">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="sponsorships.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {sponsorships.map(post => {
                    const title = post.frontmatter.title || post.fields.slug
                    const pagelink = post.frontmatter.pagelink;
                    var image = post.frontmatter.thumbnail || noImage
                    var splitPath = image.split("static/")

                    if (splitPath.length > 1) {
                        image = splitPath[0] + splitPath[1]
                    }
                    return (
                        <a href={pagelink} target="_blank" rel="noreferrer" className="card-holder">
                            <div className="card" style={{height: "425px"}}>
                                <div className="row" style={{height: "50%"}}>
                                    <div className="card-body d-flex flex-column">
                                        <div className="row" style={{height: "90%"}}>
                                            <div className="align-self-stretch"><h1 className="card-title">{title}</h1></div>
                                            <div className="align-self-stretch"><p className="card-text">{post.frontmatter.description}</p></div>
                                        </div>
                
                                    </div>
                                </div>
                                <div className="row">
                                    <img src={image} className="card-image" alt={post.frontmatter.alt} loading="lazy"/>
                                </div>
                            </div>
                        </a>
                    )})}
                </div>
            </div>
        </section>
    )
}

export default Sponsorships;